export const config = {
    environnement: process.env.ENVIRONMENT,
    brandDark: "brand-dark.svg",
    brandLight: "brand-light.svg",
    logo192: "logo-192x192.svg",
    logo512: "logo-512x512.svg",
    logo: "logo.svg",
    darkMode: "dark",
    lightMode: "light",
    theme: "theme",
    token: "jwt_token",
    user: "user",
    usersDataType: "usersDataType",
    permissionsDataType: "permissionsDataType",
    acronymsDataType: "acronymsDataType",
    modelsDataType: "modelsDataType",
    kbGroupsDataType: "kbGroupsDataType",
    charactersDataType: "charactersDataType",
    modelTemplateDataType: "modelTemplateDataType",
    systemPromptsDataType: "systemPromptsDataType",
    entityDataType: "entityDataType",
    appSettingsDataType: "appSettingsDataType",
    baseSettingsDataType: "baseSettingsDataType",
    appDataType: "appDataType",
    true: "true",
    false: "false",
    yes: "yes",
    no: "no",
    complianceRate: {
        label: "complianceRate",
        tooltip: "complianceRateTooltip",
        class: "compliance",
    },
    confidenceScore: {
        label: "confidenceScore",
        tooltip: "confidenceScoreTooltip",
        class: "confidence",
    },
    sources: {
        label: "sources",
        tooltip: "sourcesTooltip",
        class: "source",
    },
    KB: {
        label: "KB",
        tooltip: "kbTooltip",
        class: "kb",
    },
    rateTooltip: "rateTooltip",
    settingsTooltip: "settingsTooltip",
    starTooltip: "starTooltip",
    clipboardTooltip: "clipboardTooltip",
    fileTooltip: { csv: "fileTooltip.csv", pdf: "fileTooltip.pdf" },
    minComplianceRate: 0.55,
    maxComplianceRate: 0.65,
    maxTrustScore: 0.9,
    minTrustScore: 0.8,
    roles: {
        user: "user",
        admin: "admin",
        super_admin: "super_admin",
        support: "support",
        tester: "tester",
    },
    prod: "prod",
    dev: "dev",
    isFirstConnection: "is_first_connection",
    applyTranslation: "apply_translation",
    permissions: "permissions",
    role: "role",
    forceCsvFormatVectorization: "force_csv_format_vectorization",
    automaticUpdate: "automatic_update",
    language: "language",
    en: {
        lang: "en",
        months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
    },
    fr: {
        lang: "fr",
        months: [
            "janvier",
            "février",
            "mars",
            "avril",
            "mai",
            "juin",
            "juillet",
            "août",
            "septembre",
            "octobre",
            "novembre",
            "décembre",
        ],
    },
    stopWords: [
        "stop",
        "stoppe",
        "stoppé",
        "stopper",
        "stoppes",
        "stoppés",
        "stoppons",
        "stoppez",
        "terminé",
        "terminer",
        "termines",
        "terminés",
        "terminée",
        "terminées",
        "termine",
        "terminons",
        "terminez",
        "terminait",
    ],
    validExtensions: { csv: [".csv"], acronyms: [".sql"], pdf: [".pdf"] },
    manager: { characters: "characters" },
    adminTabs: {
        users: "users",
        permissions: "permissions",
        models: "models",
        modelTemplates: "modelTemplates",
        systemPrompts: "systemPrompts",
        tests: "tests",
        entity: "entity",
        appSettings: "appSettings",
        app: "app",
        kbGroupsTab: "kbGroupsTab",
        kbDocumentsTab: "kbDocumentsTab",
        systemTab: "systemTab",
        hyperparametersTab: "hyperparametersTab",
        historyTab: "historyTab",
        unansweredMessagesTab: "unansweredMessagesTab",
        feedbackTab: "feedbackTab",
        acronymsTab: "acronymsTab",
    },
    disabledEnter: "disabledEnter",
    programmingLanguages: [
        "javascript",
        "python",
        "java",
        "c++",
        "c#",
        "csharp",
        "ruby",
        "php",
        "swift",
        "objective-c",
        "kotlin",
        "scala",
        "go",
        "rust",
        "typescript",
        "shell",
        "c",
        "dart",
        "r",
        "perl",
        "lua",
        "powershell",
        "assembly",
        "matlab",
        "cobol",
        "fortran",
        "f#",
        "clojure",
        "haskell",
        "elixir",
        "erlang",
        "julia",
        "solidity",
        "vba",
        "pascal",
        "pl/sql",
        "prolog",
        "ada",
        "lisp",
        "scheme",
        "smalltalk",
        "tcl",
        "racket",
        "vbscript",
        "actionscript",
        "forth",
        "verilog",
        "vhdl",
        "apex",
        "abap",
        "crystal",
        "ocaml",
        "rexx",
        "rpg",
        "sas",
        "stata",
        "awk",
        "sed",
        "d",
        "nim",
        "ballerina",
        "elm",
        "idris",
        "j",
        "kotlin/native",
        "labview",
        "logo",
        "ml",
        "modula-3",
        "nemerle",
        "oberon",
        "pure",
        "q",
        "simula",
        "turing",
        "vala",
        "wolfram",
        "xquery",
        "zsh",
        "html",
        "css",
        "scss",
        "less",
        "json",
        "xml",
        "yaml",
        "graphql",
        "markdown",
        "sql",
        "xslt",
        "bash",
        "groovy",
    ],
    minFeedbackCharValue: 16,
    dateFormat: {
        fr: "dd MMMM yyyy HH:mm",
        en: "MMMM dd, yyyy HH:mm",
    },
};

export let apiUrl;

if (config.environnement === "dev") {
    apiUrl = "http:" + process.env.LOCAL_API_URL + process.env.APP_PORT;
} else if (
    config.environnement === "prod" ||
    config.environnement === "podman" ||
    config.environnement === "docker"
) {
    apiUrl = "https:" + process.env.PUBLIC_API_URL;
} else if (config.environnement === "azure") {
    apiUrl = "https:" + process.env.LOCAL_API_URL;
} else {
    throw new Error("Unknown environnement: " + config.environnement);
}

export let websocketUrl;

if (config.environnement === "dev") {
    websocketUrl =
        "ws:" + process.env.LOCAL_API_URL + process.env.APP_PORT + "/api";
} else if (
    config.environnement === "prod" ||
    config.environnement === "podman" ||
    config.environnement === "docker"
) {
    websocketUrl = "wss:" + process.env.PUBLIC_API_URL;
} else if (config.environnement === "azure") {
    websocketUrl = "wss:" + process.env.LOCAL_API_URL + "/api";
} else {
    throw new Error("Unknown environnement: " + config.environnement);
}

export function getBasePath() {
    if (process.env.SUBDIRECTORY && config.environnement == "prod") {
        return "/" + process.env.SUBDIRECTORY;
    }
    const pathParts = window.location.pathname.split("/");
    if (
        pathParts.length > 1 &&
        pathParts[1] &&
        (config.environnement == "prod" ||
            config.environnement === "podman" ||
            config.environnement === "docker")
    ) {
        return "/" + pathParts[1];
    }
    return "";
}

export const basePath = getBasePath();

export const components = [
    "AdminManagementComponent",
    "AppComponent",
    "ChatFormComponent",
    "DeleteConfirmationComponent",
    "FeedbackComponent",
    "ForgotPasswordComponent",
    "LoginComponent",
    "LogoutConfirmationComponent",
    "MainComponent",
    "NavbarComponent",
    "SetPasswordComponent",
    "SettingsComponent",
    "SideBarComponent",
    "ToastComponent",
    "UserGuideComponent",
    "UsersManagementComponent",
    "VerificationCodeComponent",
    "WaitingScreenComponent",
    "UpdateAppComponent",
    "ScrollBottomComponent",
    "ValidationComponent",
    "ModelsManagementComponent",
    "ModelTemplateManagementComponent",
    "ProgressToastComponent",
    "SystemPromptsManagementComponent",
    "EntityManagementComponent",
    "TestsManagementComponent",
    "AppSettingsManagementComponent",
    "CharactersManagerComponent",
    "AppManagementComponent",
    "ApiKeyComponent",
    "KbGroupManagementComponent",
    "KbDocumentManagementComponent",
    "SystemDatasManagementComponent",
    "BaseSettingsManagementComponent",
    "HistoryLogsManagementComponent",
    "UnansweredMessagesManagementComponent",
    "FeedbackManagementComponent",
    "ExportToPdfComponent",
    "AcronymsManagementComponent",
    "MaintenanceModalComponent",
    "PermissionsManagementComponent",
];
