import { AbstractUtils } from "./AbstractUtils.js";
import { TextUtils } from "./TextUtils.js";
import { UIUtils } from "./UIUtils.js";

export class ValidationUtils extends AbstractUtils {
    static checkInputs(
        inputs,
        button,
        func,
        state,
        equalityFunc = null,
        addClassToInput = false,
        updateBtnState = true,
        addOnlyValidClass = false
    ) {
        let areInputsValid = true;
        for (let input of inputs) {
            if (func(input.value)) {
                areInputsValid = false;
                break;
            }
        }
        if (areInputsValid && equalityFunc) {
            areInputsValid = equalityFunc(inputs[0].value, inputs[1].value);
        }
        state = areInputsValid;
        if (updateBtnState) {
            UIUtils.updateButtonState(!state, button);
        }
        if (addClassToInput) {
            UIUtils.updateInputState(state, inputs[0], addOnlyValidClass);
        }
        if (addOnlyValidClass) {
            UIUtils.updateInputState(state, inputs[0], addOnlyValidClass);
        }
        return state;
    }

    static isEmpty(value) {
        return value.trim() === "";
    }

    static isBetween(value, min, max) {
        return min < value.trim().length && value.trim().length < max;
    }
    static isNotNull(value) {
        return value.trim() !== "null";
    }

    static isNumber(value) {
        return Number.isInteger(parseInt(value)) && parseInt(value) > 0;
    }

    static isNumberOrZero(value) {
        return Number.isInteger(parseInt(value));
    }

    static isLongerThan(value, limit) {
        return value.trim().length < limit;
    }

    static isLessThan(value, limit) {
        return value.trim().length < limit && value.trim().length > 0;
    }

    static isLessThanAndIsNotEmpty(value, limit) {
        return value.trim().length > limit || value.trim().length == 0;
    }

    static isEqualTo(value, limit) {
        return value.trim().length != limit;
    }

    static patternTest(pattern, value) {
        if (pattern.test(value)) {
            return false;
        } else {
            return true;
        }
    }

    static areValuesEqual(valueA, valueB) {
        return valueA !== valueB;
    }

    static isEmail(value) {
        const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return !pattern.test(value.trim());
    }

    static isUrl(value) {
        const urlPattern = new RegExp(
            "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
            "i"
        );
        return !!urlPattern.test(value);
    }

    static passwordRegex = {
        caractersMinLen: 8,
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        digit: /\d/,
        specialCaracter: /[!@#$%^&*(),.?":{}|<>]/,
    };

    static isWordList(str) {
        let words = str.split(",");
        for (let i = 0; i < words.length; i++) {
            let word = words[i].trim();
            if (!word || !/^[A-Za-zÀ-ÖØ-öø-ÿ<>\/|#: _\n]+$/.test(word)) {
                return false;
            }
        }
        return true;
    }

    static isInString(baseString, string) {
        const baseElements = baseString
            .split(",")
            .map((element) => element.trim());
        for (const element of baseElements) {
            const pattern = new RegExp(TextUtils.escapeRegExp(element), "g");
            const matchCount = (string.match(pattern) || []).length;
            if (matchCount !== 1) {
                return false;
            }
        }
        return true;
    }

    static isEvenNumber(num) {
        return num % 2 === 0;
    }
}
