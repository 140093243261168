import { AbstractUtils } from "./AbstractUtils";

export class UIUtils extends AbstractUtils {
  static updateInputState(state, input, addOnlyValidClass = false) {
    if (addOnlyValidClass) {
      if (state) {
        input.classList.add("is-valid");
      } else {
        input.classList.remove("is-valid");
      }
    } else {
      if (state) {
        input.classList.remove("is-invalid");
        input.classList.add("is-valid");
      } else {
        input.classList.remove("is-valid");
        input.classList.add("is-invalid");
      }
    }
  }
  static updateButtonState(state, button) {
    if (state) {
      button.disabled = true;
    } else {
      button.disabled = false;
    }
  }

  static updateCheckedState(state, switcher) {
    if (state) {
      switcher.checked = true;
    } else {
      switcher.checked = false;
    }
  }

  static addOrRemoveElementFromBody(isDisplayed, element) {
    if (isDisplayed) {
      console.log(element);
      document.body.append(element);
    } else {
      element.remove();
    }
  }

  static updateDisplayedState(state, element, classList = []) {
    if (state) {
      element.classList.add(...classList);
      if (element.classList.contains("d-none")) {
        element.classList.remove("d-none");
      } else {
        element.style.display = "block";
      }
    } else {
      element.classList.remove(...classList);
      if (element.classList.contains("d-flex")) {
        element.classList.add("d-none");
      } else {
        element.style.display = "none";
      }
    }
  }

  static isElementHidden(elementId) {
    const element = document.getElementById(elementId);
    if (!element) {
      throw new Error(`Element with id "${elementId}" not found.`);
    }
    const computedStyle = window.getComputedStyle(element);
    return computedStyle.display === "none";
  }

  static removeClasses(elements, classes) {
    elements.forEach((element) => {
      classes.forEach((cls) => {
        element.classList.remove(cls);
      });
    });
  }

  static isScrollable(container) {
    return container.scrollHeight > container.clientHeight;
  }
}
