import { store, initialState } from "../store/store";
import { AbstractUtils } from "./AbstractUtils";

export class EventUtils extends AbstractUtils {
    static successEvent(message) {
        const successEvent = new CustomEvent("success-event", {
            detail: { message: message },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(successEvent);
    }
    static progressEvent(progress, currentFile = null, totalFiles = null) {
        const progressEvent = new CustomEvent("progress-event", {
            detail: {
                progress: progress,
                currentFile: currentFile,
                totalFiles: totalFiles,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(progressEvent);
    }
    static progressStartedEvent(title, body) {
        const progressStartedEvent = new CustomEvent("progress-started-event", {
            detail: { title: title, body: body },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(progressStartedEvent);
    }
    static doFoldersExistForThisUserEvent(
        isBasedOnDocumentsSwitch,
        isInternet,
        applyInstruction
    ) {
        const doFoldersExistForThisUserEvent = new CustomEvent(
            "do-folders-exist-for-this-user-event",
            {
                detail: {
                    isBasedOnDocumentsSwitch: isBasedOnDocumentsSwitch,
                    isInternet: isInternet,
                    applyInstruction: applyInstruction,
                },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(doFoldersExistForThisUserEvent);
    }

    static displayModelsEvent(datas) {
        const displayModelsEvent = new CustomEvent("display-models-event", {
            detail: { datas: datas },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(displayModelsEvent);
    }

    static displayUsersEvent(datas) {
        const displayUsersEvent = new CustomEvent("display-users-event", {
            detail: { datas: datas },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(displayUsersEvent);
    }

    static displayPermissionsEvent(datas) {
        const displayPermissionsEvent = new CustomEvent(
            "display-permissions-event",
            {
                detail: { datas: datas },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(displayPermissionsEvent);
    }

    static displayAcronymsEvent(datas) {
        const displayUsersEvent = new CustomEvent("display-acronyms-event", {
            detail: { datas: datas },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(displayUsersEvent);
    }

    static resetToDefaultValuesEvent(datas) {
        const resetToDefaultValuesEvent = new CustomEvent(
            "reset-to-default-values-event",
            {
                detail: { datas: datas },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(resetToDefaultValuesEvent);
    }

    static warningEvent(message) {
        const successEvent = new CustomEvent("warning-event", {
            detail: { message: message },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(successEvent);
    }

    static isInternetEvent(isInternet) {
        const isInternetEvent = new CustomEvent("is-internet-event", {
            detail: { message: isInternet },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(isInternetEvent);
    }

    static displayScrollBottomEvent(display, container) {
        const displayScrollBottomEvent = new CustomEvent(
            "display-scroll-bottom-event",
            {
                detail: { display: display, container: container },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(displayScrollBottomEvent);
    }

    static errorEvent(message) {
        const errorEvent = new CustomEvent("error-event", {
            detail: { message: message },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(errorEvent);
    }

    static tokenValidityEvent(isValid, userId, hasSeenUserGuide) {
        const tokenValidityEvent = new CustomEvent("token-validity-event", {
            detail: {
                isValid: isValid,
                userId: userId,
                hasSeenUserGuide: hasSeenUserGuide,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(tokenValidityEvent);
    }

    static tokenValidEvent(data) {
        const tokenValidEvent = new CustomEvent("token-valid-event", {
            detail: {
                data: data,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(tokenValidEvent);
    }

    static deleteConfirmationEvent(id, objToDelete) {
        const deleteConfirmationEvent = new CustomEvent(
            "delete-confirmation-event",
            {
                detail: { id: id, objToDelete: objToDelete },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(deleteConfirmationEvent);
    }

    static feedbackEvent(
        messageId,
        isForced = false,
        button = null,
        hr = null
    ) {
        const feedbackEvent = new CustomEvent("feedback-event", {
            detail: {
                messageId: messageId,
                isForced: isForced,
                button: button,
                hr: hr,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(feedbackEvent);
    }

    static displayAppEvent(data) {
        const displayAppEvent = new CustomEvent("display-app-event", {
            detail: {
                token: data.access_token,
                user: data.user,
                isFirstConnection: data.is_first_connection,
                role: data.role,
                userId: data.user_id,
                hasSeenUserGuide: data.has_seen_user_guide,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(displayAppEvent);
    }

    static loggedEvent(data) {
        const loggedEvent = new CustomEvent("logged-event", {
            detail: {
                data: data,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(loggedEvent);
    }

    static resetConversationEvent(data) {
        const resetConversationEvent = new CustomEvent(
            "reset-conversation-event",
            {
                detail: {
                    data: data,
                },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(resetConversationEvent);
    }

    static getConversationEvent(id) {
        const getConversationEvent = new CustomEvent("get-conversation-event", {
            detail: {
                id: id,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(getConversationEvent);
    }

    static getImageEvent(
        imageElement,
        imageName,
        chatMessageElement,
        placeholder,
        fromWebsocket = false,
        isUser = false
    ) {
        const getImageEvent = new CustomEvent("get-image-event", {
            detail: {
                element: imageElement,
                imageName: imageName,
                chatMessageElement: chatMessageElement,
                placeholder: placeholder,
                fromWebsocket: fromWebsocket,
                isUser: isUser,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(getImageEvent);
    }

    static fetchConversationEvent(
        id,
        chatMessages,
        chatContainer,
        newConversation,
        programmingLanguages,
        state
    ) {
        const fetchConversationEvent = new CustomEvent(
            "fetch-conversation-event",
            {
                detail: {
                    id: id,
                    chatMessages: chatMessages,
                    chatContainer: chatContainer,
                    newConversation: newConversation,
                    programmingLanguages: programmingLanguages,
                    state: state,
                },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(fetchConversationEvent);
    }

    static validationEvent(
        label,
        body,
        close,
        validate,
        callback,
        callbackArgs = null
    ) {
        const validationEvent = new CustomEvent("validation-event", {
            detail: {
                label: label,
                body: body,
                close: close,
                validate: validate,
                callback: callback,
                callbackArgs: callbackArgs,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(validationEvent);
    }

    static addModelEvent(
        name,
        url,
        type,
        quantization,
        isUsed,
        modelTemplate,
        threads
    ) {
        const addModelEvent = new CustomEvent("add-model-event", {
            detail: {
                name: name,
                url: url,
                type: type,
                quantization: quantization,
                isUsed: isUsed,
                modelTemplate: modelTemplate,
                threads: threads,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(addModelEvent);
    }

    static addCharacterEvent(name, systemPrompt) {
        const addCharacterEvent = new CustomEvent("add-character-event", {
            detail: {
                name: name,
                system_prompt: systemPrompt,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(addCharacterEvent);
    }

    static updateCharacterEvent(name, systemPrompt, id) {
        const updateCharacterEvent = new CustomEvent("update-character-event", {
            detail: {
                name: name,
                system_prompt: systemPrompt,
                id: id,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(updateCharacterEvent);
    }

    static addUserEvent(role, username, kbGroups = null) {
        const addUserEvent = new CustomEvent("add-user-event", {
            detail: {
                role: role,
                username: username,
                kbGroups: kbGroups,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(addUserEvent);
    }

    static updateUserEvent(role, username, kbGroups, id) {
        const updateUserEvent = new CustomEvent("update-user-event", {
            detail: {
                role: role,
                username: username,
                kbGroups: kbGroups,
                id: id,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(updateUserEvent);
    }

    static deleteAcronymEvent(id) {
        const deleteModelTemplateEvent = new CustomEvent(
            "delete-acronym-event",
            {
                detail: {
                    id: id,
                },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(deleteModelTemplateEvent);
    }

    static deleteModelTemplateEvent(id) {
        const deleteModelTemplateEvent = new CustomEvent(
            "delete-model-template-event",
            {
                detail: {
                    id: id,
                },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(deleteModelTemplateEvent);
    }

    static deleteKbGroupEvent(id) {
        const deleteKbGroupEvent = new CustomEvent("delete-kb-group-event", {
            detail: {
                id: id,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(deleteKbGroupEvent);
    }

    static characterUpdatedEvent(data) {
        const characterUpdatedEvent = new CustomEvent(
            "character-updated-event",
            {
                detail: {
                    data: data,
                },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(characterUpdatedEvent);
    }

    static deleteSystemPromptsEvent(id) {
        const deleteSystemPromptEvent = new CustomEvent(
            "delete-system-prompts-event",
            {
                detail: {
                    id: id,
                },
                bubbles: true,
                composed: true,
            }
        );
        document.dispatchEvent(deleteSystemPromptEvent);
    }

    static deleteModelEvent(id) {
        const deleteModelEvent = new CustomEvent("delete-model-event", {
            detail: {
                id: id,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(deleteModelEvent);
    }

    static deleteCharacterEvent(id) {
        const deleteCharacterEvent = new CustomEvent("delete-character-event", {
            detail: {
                id: id,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(deleteCharacterEvent);
    }

    static deleteUserEvent(id) {
        const deleteUserEvent = new CustomEvent("delete-user-event", {
            detail: {
                id: id,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(deleteUserEvent);
    }

    static customEvent(data, eventName) {
        const customEvent = new CustomEvent(eventName, {
            detail: {
                data: data,
            },
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(customEvent);
    }

    static logoutEvent() {
        this.createSimpleEvent("logout-event");
    }

    static loggedOutEvent() {
        this.createSimpleEvent("logged-out-event");
    }

    static verificationEvent() {
        this.createSimpleEvent("verification-event");
    }

    static resetEvent() {
        store.state = { ...initialState };
        this.createSimpleEvent("reset-event");
    }

    static forceChangePasswordEvent() {
        this.createSimpleEvent("force-change-password-event");
    }

    static showSideBarEvent() {
        this.createSimpleEvent("show-sidebar-event");
    }

    static hideSideBarEvent() {
        this.createSimpleEvent("hide-sidebar-event");
    }

    static conversationDeletedEvent() {
        this.createSimpleEvent("conversation-deleted-event");
    }

    static darkModeEvent() {
        this.createSimpleEvent("dark-mode-event");
    }

    static disableInteractionsEvent() {
        this.createSimpleEvent("disable-interactions-event");
    }

    static enableInteractionsEvent() {
        this.createSimpleEvent("enable-interactions-event");
    }

    static fetchAppEvent() {
        this.createSimpleEvent("fetch-app-event");
    }

    static createConversationEvent() {
        this.createSimpleEvent("create-conversation-event");
    }

    static initAppEvent() {
        this.createSimpleEvent("init-app-event");
    }

    static logoutRequestEvent() {
        this.createSimpleEvent("logout-request-event");
    }

    static updateSettingsEvent() {
        this.createSimpleEvent("update-settings-event");
    }

    static forgotPasswordEvent() {
        this.createSimpleEvent("forgot-password-event");
    }

    static newPasswordEvent() {
        this.createSimpleEvent("new-password-event");
    }

    static updatePasswordEvent() {
        this.createSimpleEvent("update-password-event");
    }

    static showApiKeyEvent() {
        this.createSimpleEvent("show-api-key-event");
    }

    static openSettingsModalEvent() {
        this.createSimpleEvent("open-settings-modal-event");
    }

    static openAdminManagementEvent() {
        this.createSimpleEvent("open-admin-management-event");
    }

    static manageCharactersEvent() {
        this.createSimpleEvent("manage-characters-event");
    }

    static applyTraduction() {
        this.createSimpleEvent("apply-traduction-event");
    }

    static isConnectedEvent() {
        this.createSimpleEvent("is-connected-event");
    }

    static resetFormSettingsEvent() {
        this.createSimpleEvent("reset-form-settings-event");
    }

    static updateAppEvent() {
        this.createSimpleEvent("update-app-event");
    }

    static showLoaderEvent() {
        this.createSimpleEvent("show-loader-event");
    }

    static hideLoaderEvent() {
        this.createSimpleEvent("hide-loader-event");
    }

    static hasRecordedEvent() {
        this.createSimpleEvent("has-recorded-event");
    }

    static stopRecordEvent() {
        this.createSimpleEvent("stop-record-event");
    }

    static hasStoppedStreamingEvent() {
        this.createSimpleEvent("has-stopped-streaming-event");
    }

    static getUsersEvent() {
        this.createSimpleEvent("get-users-event");
    }

    static getPermissionsEvent() {
        this.createSimpleEvent("get-permissions-event");
    }

    static getModelsEvent() {
        this.createSimpleEvent("get-models-event");
    }

    static getAppInfosEvent() {
        this.createSimpleEvent("get-app-infos-event");
    }

    static getModelTemplatesEvent() {
        this.createSimpleEvent("get-model-templates-event");
    }

    static getSystemPromptsEvent() {
        this.createSimpleEvent("get-system-prompts-event");
    }

    static getTestsEvent() {
        this.createSimpleEvent("get-tests-event");
    }

    static getEntityEvent() {
        this.createSimpleEvent("get-entity-event");
    }

    static getAppSettingsEvent() {
        this.createSimpleEvent("get-app-settings-event");
    }

    static getAppEvent() {
        this.createSimpleEvent("get-app-event");
    }

    static getKbGroupsEvent() {
        this.createSimpleEvent("get-kb-groups-event");
    }

    static getKbDocumentsEvent() {
        this.createSimpleEvent("get-kb-documents-event");
    }

    static getSystemDatasEvent() {
        this.createSimpleEvent("get-system-datas-event");
    }

    static getBaseSettingsEvent() {
        this.createSimpleEvent("get-base-settings-event");
    }

    static getHistoryLogsEvent() {
        this.createSimpleEvent("get-history-logs-event");
    }

    static getUnansweredMessagesEvent() {
        this.createSimpleEvent("get-unanswered-messages-event");
    }

    static getFeedbacksEvent() {
        this.createSimpleEvent("get-feedbacks-event");
    }

    static getAcronymsEvent() {
        this.createSimpleEvent("get-acronyms-event");
    }

    static characterDeletedEvent() {
        this.createSimpleEvent("character-deleted-event");
    }

    static progressFinishedEvent() {
        this.createSimpleEvent("progress-finished-event");
    }

    static appInfosReceivedEvent() {
        this.createSimpleEvent("app-infos-received-event");
    }

    static passwordMismatchEvent() {
        this.createSimpleEvent("password-mismatch-event");
    }

    static validateInputEvent() {
        this.createSimpleEvent("validate-input-event");
    }

    static characterConversationUpdatedEvent() {
        this.createSimpleEvent("character-conversation-updated-event");
    }

    static updatedAppEvent() {
        this.createSimpleEvent("updated-app-event");
    }

    static deleteUserCollectionEvent() {
        EventUtils.createSimpleEvent("delete-user-collection-event");
    }

    static deleteBaseCollectionEvent() {
        EventUtils.createSimpleEvent("delete-base-collection-event");
    }

    static createSimpleEvent(event) {
        const simpleEvent = new CustomEvent(event, { composed: true });
        document.dispatchEvent(simpleEvent);
    }
}
