import hljs from "highlight.js";
import "highlight.js/styles/tokyo-night-dark.css";
import i18next from "i18next";
import Backend from "i18next-xhr-backend";
import { EventUtils } from "./utils/EventUtils";
import { apiUrl, components, config } from "./config/config";
import ICU from "i18next-icu";
import AudioRecorder from "audio-recorder-polyfill";
import { HTMLUtils } from "./utils/HTMLUtils.js";
import { AppService } from "./services/AppService.js";

const appService = new AppService();

if (typeof window.MediaRecorder === "undefined") {
    window.MediaRecorder = AudioRecorder;
}

appService
    .getBootstrap()
    .then((cssContent) => {
        HTMLUtils.addBootstrap(cssContent);
        i18next
            .use(ICU)
            .use(Backend)
            .init(
                {
                    lng: config.fr.lang,
                    fallbackLng: config.fr.lang,
                    debug: false,
                    ns: components,
                    defaultNS: "AppComponent",
                    backend: {
                        loadPath:
                            "./src/components/{{ns}}/locales/{{lng}}.json",
                    },
                },
                (err, t) => {
                    if (err)
                        console.error(
                            "Error during traduction files loading",
                            err
                        );
                    else {
                        import(
                            "./components/AppComponent/AppComponent.js"
                        ).then(() => {
                            hljs.highlightAll();
                            if (
                                process.env.NODE_ENV === "production" &&
                                "serviceWorker" in navigator
                            ) {
                                let manifest = null;
                                fetch(apiUrl + "/manifest")
                                    .then((response) => response.json())
                                    .then((data) => {
                                        manifest = data;
                                        console.log(
                                            "Manifest retrieved from API:",
                                            manifest
                                        );
                                        const link =
                                            document.createElement("link");
                                        link.rel = "manifest";
                                        link.href =
                                            "data:application/manifest+json," +
                                            encodeURIComponent(
                                                JSON.stringify(manifest)
                                            );
                                        document.head.appendChild(link);
                                        document.title = manifest.name;
                                        const metaThemeColor =
                                            document.querySelector(
                                                'meta[name="theme-color"]'
                                            );
                                        if (metaThemeColor) {
                                            metaThemeColor.setAttribute(
                                                "content",
                                                manifest.theme_color
                                            );
                                        } else {
                                            const newMetaThemeColor =
                                                document.createElement("meta");
                                            newMetaThemeColor.setAttribute(
                                                "name",
                                                "theme-color"
                                            );
                                            newMetaThemeColor.setAttribute(
                                                "content",
                                                manifest.theme_color
                                            );
                                            document.head.appendChild(
                                                newMetaThemeColor
                                            );
                                        }
                                    })
                                    .then(() => {
                                        manifest.icons.forEach((icon) => {
                                            if (icon.sizes === "192x192") {
                                                let linkIcon =
                                                    document.querySelector(
                                                        "link[rel='icon']"
                                                    );
                                                if (!linkIcon) {
                                                    linkIcon =
                                                        document.createElement(
                                                            "link"
                                                        );
                                                    linkIcon.setAttribute(
                                                        "rel",
                                                        "icon"
                                                    );
                                                    document.head.appendChild(
                                                        linkIcon
                                                    );
                                                }
                                                fetch(icon.src, {
                                                    mode: "no-cors",
                                                })
                                                    .then((response) =>
                                                        response.blob()
                                                    )
                                                    .then((blob) => {
                                                        let newBlob =
                                                            blob.type ===
                                                            "image/svg+xml"
                                                                ? new Blob(
                                                                      [blob],
                                                                      {
                                                                          type: "image/svg+xml",
                                                                      }
                                                                  )
                                                                : blob;
                                                        const url =
                                                            URL.createObjectURL(
                                                                newBlob
                                                            );
                                                        linkIcon.setAttribute(
                                                            "href",
                                                            url
                                                        );
                                                        linkIcon.setAttribute(
                                                            "sizes",
                                                            icon.sizes
                                                        );
                                                        linkIcon.setAttribute(
                                                            "type",
                                                            newBlob.type
                                                        );
                                                        linkIcon.onload = () =>
                                                            URL.revokeObjectURL(
                                                                url
                                                            );
                                                    })
                                                    .then(() => {
                                                        navigator.serviceWorker
                                                            .register(
                                                                "./service-worker.js"
                                                            )
                                                            .then(
                                                                (
                                                                    registration
                                                                ) => {
                                                                    console.log(
                                                                        "SW registered: ",
                                                                        registration
                                                                    );
                                                                    registration.onupdatefound =
                                                                        () => {
                                                                            const installingWorker =
                                                                                registration.installing;
                                                                            installingWorker.onstatechange =
                                                                                () => {
                                                                                    if (
                                                                                        installingWorker.state ===
                                                                                        "installed"
                                                                                    ) {
                                                                                        if (
                                                                                            navigator
                                                                                                .serviceWorker
                                                                                                .controller
                                                                                        ) {
                                                                                            EventUtils.resetEvent();
                                                                                            EventUtils.updateAppEvent();
                                                                                        }
                                                                                    }
                                                                                };
                                                                        };
                                                                }
                                                            )
                                                            .catch(
                                                                (
                                                                    registrationError
                                                                ) => {
                                                                    console.log(
                                                                        "SW registration failed: ",
                                                                        registrationError
                                                                    );
                                                                }
                                                            );
                                                    })
                                                    .catch((error) =>
                                                        console.error(
                                                            "Error loading icon:",
                                                            error
                                                        )
                                                    );
                                            }
                                        });
                                    });
                            }
                        });
                    }
                }
            );
    })
    .catch((error) => {
        console.error("Failed to fetch CSS:", error);
        const noContent = document.getElementById("no-content");
        const head = document.querySelector("head");
        const link = document.createElement("link");
        link.href =
            "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css";
        link.rel = "stylesheet";
        link.integrity =
            "sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC";
        link.crossOrigin = "anonymous";
        head.appendChild(link);
        noContent.style.display = "flex";
        noContent.style.flexDirection = "column";
        noContent.style.justifyContent = "center";
        noContent.style.alignItems = "center";
        noContent.style.height = "100vh";
        noContent.style.textAlign = "center";
    });
