import { config } from "../config/config";
import { StorageService } from "../services/StorageService";
import { AbstractUtils } from "./AbstractUtils";
import showdown from "showdown";
export class TextUtils extends AbstractUtils {
    static storageService = new StorageService();

    static truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }

    static escapeHTML(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    static isHTML(str) {
        const doc = new DOMParser().parseFromString(str, "text/html");
        return Array.from(doc.body.childNodes).some(
            (node) => node.nodeType === 1
        );
    }

    static replaceWithTags(str, sign, openTag, closeTag) {
        if (!str || !sign || !openTag || !closeTag) {
            throw new Error("Every parameter is required.");
        }
        let openTagLength = openTag.length;
        let signLength = sign.length;
        let foundSign;
        do {
            let firstIndex = str.indexOf(sign);
            if (firstIndex !== -1) {
                str =
                    str.slice(0, firstIndex) +
                    openTag +
                    str.slice(firstIndex + signLength);
                let secondIndex = str.indexOf(sign, firstIndex + openTagLength);
                if (secondIndex !== -1) {
                    str =
                        str.slice(0, secondIndex) +
                        closeTag +
                        str.slice(secondIndex + signLength);
                    foundSign = true;
                } else {
                    foundSign = false;
                }
            } else {
                foundSign = false;
            }
        } while (foundSign);

        return str;
    }

    static base64ToBlob(base64, mimeType) {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }

    static isURL(str) {
        const regex =
            /\b(?:http|https|ftp):\/\/(?:\S+(?::\S*)?@)?(?:[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+)(?:\/[^\s#]*)?#?(?:[a-zA-Z0-9-_]*)?\b/;
        return regex.test(str);
    }

    static addURLtag(str) {
        const regex =
            /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        let link = "Link";
        if (
            TextUtils.storageService.getItem(config.language) === config.fr.lang
        ) {
            link = "Lien";
        }

        const text = str.replace(regex, (url) => {
            return `<br><a href="${url}" target="_blank" rel="noopener noreferrer" class="link-primary"><svg xmlns="http://www.w3.org/2000 svg" width="16" height="16" fill="currentColor" class="bi bi-link mb-1 me-1" viewBox="0 0 16 16"><path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/><path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/></svg>${link}</a><br>`;
        });
        return text;
    }

    static escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    static getConverter() {
        return new showdown.Converter({
            disableForced4SpacesIndentedSublists: true,
            openLinksInNewWindow: true,
            emoji: true,
            simpleLineBreaks: true,
            smartIndentationFix: true,
            omitExtraWLInCodeBlocks: true,
            strikethrough: true,
            tables: true,
            smoothLivePreview: true,
        });
    }

    static addBreakLine(str, n) {
        const words = str.split(" ");
        let modified = "";

        for (let i = 0; i < words.length; i++) {
            modified += words[i] + " ";
            if ((i + 1) % n === 0 && i !== words.length - 1) {
                modified += "<br>";
            }
        }
        return modified;
    }

    static addBreakLineToArrayValues(array, x) {
        let result = "";
        for (let i = 0; i < array.length; i++) {
            result += array[i];
            if ((i + 1) % x === 0 && i + 1 !== array.length) {
                result += "\n";
            } else if (i + 1 !== array.length) {
                result += ", ";
            }
        }
        return result;
    }

    static isJsonObject(value) {
        return (
            value !== null &&
            typeof value === "object" &&
            !Array.isArray(value) &&
            !(value instanceof Date)
        );
    }

    static canBeParsedToJson(str) {
        const regex = /^\s*(\{.*\}|\[.*\])\s*$/;
        return regex.test(str);
    }

    static makeid(length) {
        let result = "";
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
            counter += 1;
        }
        return result;
    }
}
