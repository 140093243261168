import { apiUrl, config } from "../config/config";
import { StorageService } from "./StorageService";
import { EventUtils } from "../utils/EventUtils";

export class AbstractService {
    constructor() {
        this.storageService = new StorageService();
        this.url = apiUrl;
        this.baseUrl;
    }
    async getDataTypes(dataType) {
        this.storageService.removeItem(dataType);
        this.apiCall(`${this.baseUrl}/data-types`, "GET")
            .then((data) => {
                this.storageService.setItem(dataType, JSON.stringify(data));
            })
            .catch((error) => {
                console.error(error);
                if (error.detail) {
                    EventUtils.errorEvent(error.detail);
                }
            });
    }
    async apiCall(
        route,
        method,
        data = null,
        useToken = true,
        isJson = true,
        showLoader = false
    ) {
        let headers = {};

        if (isJson) {
            headers = {
                "Content-Type": "application/json",
            };
        }

        if (useToken) {
            headers["Authorization"] = `Bearer ${this.storageService.getItem(
                config.token
            )}`;
        }

        let options = {
            method: method,
            headers: headers,
        };

        if (data) {
            options.body = isJson ? JSON.stringify(data) : data;
        }

        try {
            if (showLoader) {
                EventUtils.showLoaderEvent();
            }
            let fullUrl = new URL("/api/" + route, this.url).href;
            let response = await fetch(fullUrl, options);
            if (response.ok) {
                let contentType = response.headers.get("content-type");
                if (
                    contentType &&
                    (contentType.startsWith("image/") ||
                        contentType.startsWith("text/plain") ||
                        contentType.startsWith("application/pdf") ||
                        contentType.startsWith("text/csv") ||
                        contentType.startsWith(
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ))
                ) {
                    return await response.blob();
                } else if (contentType && contentType.startsWith("text/css")) {
                    return await response.text();
                } else {
                    return await response.json();
                }
            } else if (response.status === 401) {
                EventUtils.resetEvent();
                let errorData = await response.json();
                throw errorData;
            } else if (response.status === 413) {
                if (
                    this.storageService.getItem(config.language) ===
                    config.fr.lang
                ) {
                    message = "Le fichier est trop volumineux.";
                } else {
                    message = "The file is too large.";
                }
                EventUtils.errorEvent(message);
            } else {
                let error = await response.json();
                throw error;
            }
        } catch (error) {
            if (error !== undefined) {
                console.error(error);
                throw error;
            }
        } finally {
            if (showLoader) {
                EventUtils.hideLoaderEvent();
            }
        }
    }

    async getAll(user = null) {
        try {
            let route = this.baseUrl;
            if (user) {
                route += "/" + encodeURIComponent(user);
            }
            return await this.apiCall(route, "GET", null, true, true, true);
        } catch (error) {
            throw error;
        }
    }

    async getOne(value) {
        try {
            return await this.apiCall(
                `${this.baseUrl}/${value}`,
                "GET",
                null,
                true,
                true,
                true
            );
        } catch (error) {
            throw error;
        }
    }

    async add(data, url = null) {
        if (!url) {
            url = this.baseUrl;
        }
        try {
            return await this.apiCall(url, "POST", data);
        } catch (error) {
            throw error;
        }
    }

    async delete(id) {
        try {
            return await this.apiCall(
                `${this.baseUrl}/${id}`,
                "DELETE",
                null,
                true,
                false,
                true
            );
        } catch (error) {
            throw error;
        }
    }

    async update(data) {
        try {
            let id = data.id;
            delete data.id;
            return await this.apiCall(
                `${this.baseUrl}/${id}`,
                "PUT",
                data,
                true,
                true,
                true
            );
        } catch (error) {
            throw error;
        }
    }

    async get() {
        try {
            return await this.apiCall(
                `${this.baseUrl}`,
                "GET",
                null,
                true,
                true,
                true
            );
        } catch (error) {
            throw error;
        }
    }
}
