import { SVG } from "../config/constants";
import { AbstractUtils } from "./AbstractUtils";
import { EventUtils } from "./EventUtils";
import { FileUtils } from "./FileUtils";
import { ValidationUtils } from "./ValidationUtils";
import { Modal } from "bootstrap";

export class HTMLUtils extends AbstractUtils {
    static html(literals, ...values) {
        const result = [];
        for (let i = 0; i < literals.length; i++) {
            result.push(literals[i]);
            if (
                i < values.length &&
                values[i] !== null &&
                values[i] !== undefined
            ) {
                result.push(values[i]);
            }
        }
        return result.join("");
    }

    static addBootstrap(cssContent) {
        if (document.getElementById("bootstrap")) {
            document.getElementById("bootstrap").remove();
        }
        const styleEl = document.createElement("style");
        styleEl.id = "bootstrap";
        styleEl.innerHTML = cssContent;
        document.head.appendChild(styleEl);
    }

    createAccordionItem(id, title, body, parentId) {
        let accordionItem = document.createElement("div");
        accordionItem.classList.add("accordion-item");

        let accordionTitle = document.createElement("h2");
        accordionTitle.classList.add("accordion-header");
        accordionTitle.id = "collapse-title-" + id;

        let button = document.createElement("btn");
        button.classList.add("accordion-button", "pointer");
        button.type = "button";
        button.setAttribute("data-bs-toggle", "collapse");
        button.setAttribute("data-bs-target", "#collapse-" + id);
        if (ValidationUtils.isInString("data_id_0", id)) {
            button.setAttribute("aria-expanded", false);
            button.classList.add("bg-info", "text-light");
        } else {
            button.setAttribute("aria-expanded", false);
            button.classList.add("collapsed");
        }
        button.setAttribute("aria-controls", "collapse-" + id);
        button.innerText = title;

        accordionTitle.appendChild(button);
        accordionItem.appendChild(accordionTitle);

        let collapsed = document.createElement("div");
        collapsed.id = "collapse-" + id;
        collapsed.classList.add("accordion-collapse", "collapse");
        collapsed.setAttribute("aria-labelledby", "collapse-title-" + id);
        collapsed.setAttribute("data-bs-parent", parentId);

        let accordionBody = document.createElement("div");
        accordionBody.classList.add("accordion-body");
        if (body) {
            if (body.tagName === "IMG") {
                accordionBody.style.textAlign = "center";
            }
            accordionBody.appendChild(body);
        }

        collapsed.appendChild(accordionBody);
        accordionItem.appendChild(collapsed);
        return accordionItem;
    }

    static sanitizeAndBlockImages(element) {
        if (element === null) {
            return null;
        }
        const extractFileName = (url) => {
            const parts = url.split("/");
            return parts[parts.length - 1];
        };

        if (typeof element === "string") {
            let parser = new DOMParser();
            let doc = parser.parseFromString(element, "text/html");
            doc.querySelectorAll("img").forEach((img) => {
                const fileName = extractFileName(img.src);
                img.setAttribute("data-src", fileName);
                img.removeAttribute("src");
            });
            return new XMLSerializer()
                .serializeToString(doc.body)
                .replace(/<body[^>]*>|<\/body>/g, "");
        } else {
            element.querySelectorAll("img").forEach((img) => {
                const fileName = extractFileName(img.src);
                img.setAttribute("data-src", fileName);
                img.removeAttribute("src");
            });
        }
        return element;
    }

    static handleClick(url) {
        const modalImage = document.getElementById("modalImage");
        modalImage.src = url;
        modalImage.classList.add("w-100");
        new Modal(document.getElementById("imageModal")).show();
    }

    static async processImages(element, conversationService) {
        const imgTags = element.querySelectorAll("img");
        const imgArray = Array.from(imgTags);
        const processImage = async (img) => {
            if (img.getAttribute("data-src")) {
                const fileName = img.getAttribute("data-src").split("/").pop();
                const extensions = [".png", ".jpg", ".jpeg"];
                if (extensions.includes(FileUtils.getExtension(fileName))) {
                    try {
                        const data = await conversationService.getImage(
                            fileName
                        );
                        if (!(data instanceof Blob)) {
                            return;
                        }
                        const url = URL.createObjectURL(data);
                        const brBefore = document.createElement("br");
                        const brAfter = document.createElement("br");

                        img.before(brBefore);
                        img.after(brAfter);
                        img.src = url;
                        img.classList.add(
                            "rounded",
                            "pointer",
                            "sources-image"
                        );
                        img.loading = "lazy";

                        if (!img.hasAttribute("data-hasClickListener")) {
                            img.addEventListener("click", () =>
                                HTMLUtils.handleClick(url)
                            );
                            img.setAttribute("data-hasClickListener", "true");
                        }
                    } catch (error) {
                        console.error(error);
                        if (error.detail) {
                            EventUtils.errorEvent(error.detail);
                        }
                    }
                }
            }
        };
        await Promise.all(imgArray.map(processImage));
    }

    static addLineAboveCode() {
        const copyPasteBtn = document.createElement("div");
        copyPasteBtn.innerHTML = HTMLUtils.html`${SVG.CLIPBOARD}
        Copy code`;
        copyPasteBtn.classList.add("copy-code", "small");
        const lineAboveCodeElement = document.createElement("div");
        lineAboveCodeElement.classList.add(
            "line-above",
            "mt-3",
            "border-bottom",
            "bg-dark",
            "text-light",
            "d-flex",
            "flex-row",
            "justify-content-between",
            "align-items-start",
            "p-2"
        );
        lineAboveCodeElement.appendChild(copyPasteBtn);
        return lineAboveCodeElement;
    }

    static addCopyPasteToCodeBlocks(element) {
        element.addEventListener("click", (event) => {
            if (event.target.classList.contains("copy-code")) {
                let el =
                    event.target.parentElement.nextElementSibling.querySelector(
                        "code"
                    );
                let textToCopy = el.innerText;
                navigator.clipboard
                    .writeText(textToCopy)
                    .then(() => {
                        event.target.innerHTML = HTMLUtils.html`${SVG.CLIPBOARD_CHECK} Code copied!`;
                    })
                    .catch((error) => {
                        let hiddenInput = document.createElement("input");
                        hiddenInput.setAttribute("type", "text");
                        hiddenInput.setAttribute("value", textToCopy);
                        document.body.appendChild(hiddenInput);
                        hiddenInput.select();
                        document.execCommand("copy");
                        document.body.removeChild(hiddenInput);
                        event.target.innerHTML = HTMLUtils.html`${SVG.CLIPBOARD_CHECK} Code copied!`;
                    });
            }
        });
    }
}
