import { config } from "../config/config";

export const initialState = {
    isBasedOnDocuments: false,
    activeConversationId: null,
    applyTranslation: false,
    customPrompt: "",
    applyCustomPrompt: false,
    isForgotPasswordEmailInputValid: false,
    isCaptchaCodeInputValid: false,
    forgotPasswordValid: false,
    codeForPasswordChange: null,
    user: null,
    temperature: null,
    topP: null,
    topK: null,
    retrieverThreshold: null,
    applyCustomSettings: null,
    canScrollTo: true,
    isRecording: false,
    transcript: "",
    hasRecorded: false,
    applyAudio: false,
    isAssistant: false,
    hasReceivedAllAudios: false,
    audioChunks: [],
    blob: null,
    mediaRecorder: null,
    isInternet: false,
    isAuthentified: false,
    character: null,
    characterImageName: null,
    isSidebarOpen: false,
    isSmartphone: false,
    isAudioAvailable: true,
    activeAdminTab: config.adminTabs.users,
    useWhisper: true,
    useImageGen: true,
    useTraductors: true,
    isFirstLoading: true,
    isDownloading: false,
    isTesting: false,
    objToDelete: null,
    objIdToDelete: null,
    feebackMessageId: null,
    isSubmitFeedbackBtnDisabled: true,
    isForgotPasswordSubmitDisabled: true,
    isForgotPasswordCodeSubmitDisabled: true,
    isloginSubmitDisabled: true,
    isLoginFormDisplayed: true,
    isMainDisplayed: false,
    isChangePasswordBtnDisabled: true,
    isFromForgot: false,
    isCustomPromptSwitchDisabled: false,
    isFileSpinnerDisplayed: false,
    isKbDocumentFileSpinnerDisplayed: false,
    isDarkModeSwitchChecked: true,
    isVerifyBtnDisabled: true,
    isMultimodal: false,
    maxCaracters: null,
    appName: null,
    displayChart: null,
    useForceFeedback: false,
    hasAnsweredToForceFeedback: true,
    forceCsvFormatVectorization: false,
    automaticUpdate: false,
};

export const store = {
    state: { ...initialState },
};
