import { config } from "../config/config.js";
import { EventUtils } from "../utils/EventUtils.js";

export class StorageService {
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    if (key) {
      return JSON.parse(localStorage.getItem(key));
    }
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }

  initLocalStorage() {
    try {
      if (!this.getItem(config.theme)) {
        this.setItem(config.theme, config.lightMode);
      }
    } catch (error) {
      EventUtils.resetEvent();
    }
    this.setItem(config.disabledEnter, "false");
  }
}
