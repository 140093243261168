import { AbstractService } from "./AbstractService";

export class AppService extends AbstractService {
  constructor() {
    super();
    this.baseUrl = "app";
  }
  async updateApp(obj) {
    try {
      let formData = new FormData();
      let id = obj.id;
      let documentTitle = obj.document_title;
      let name = obj.name;
      let shortName = obj.short_name;
      let description = obj.description;
      let version = obj.version;
      let brandDark = obj.brand_dark;
      let brandLight = obj.brand_light;
      let logo = obj.logo;
      let logo192 = obj.logo_192;
      let logo512 = obj.logo_512;
      let bootstrap = obj.bootstrap;
      let datas = {
        document_title: documentTitle,
        name: name,
        version: version,
        description: description,
        short_name: shortName,
        bootstrap: bootstrap,
      };
      formData.append("datas", JSON.stringify(datas));
      if (brandDark.length > 0) {
        formData.append("brand_dark", brandDark[0]);
      }
      if (brandLight.length > 0) {
        formData.append("brand_light", brandLight[0]);
      }
      if (logo.length > 0) {
        formData.append("logo", logo[0]);
      }
      if (logo192.length > 0) {
        formData.append("logo_192", logo192[0]);
      }
      if (logo512.length > 0) {
        formData.append("logo_512", logo512[0]);
      }
      return await this.apiCall(
        `${this.baseUrl}/${id}`,
        "PUT",
        formData,
        true,
        false,
        true
      );
    } catch (error) {
      throw error;
    }
  }
  async getAppInfos() {
    try {
      return await this.apiCall(`app-infos`, "GET");
    } catch (error) {
      throw error;
    }
  }

  async getIcon(icon) {
    try {
      return await this.apiCall(`app-icon/${icon}`, "GET");
    } catch (error) {
      throw error;
    }
  }

  async getDocumentTitle() {
    try {
      return await this.apiCall(`document-title`, "GET");
    } catch (error) {
      throw error;
    }
  }

  async getBootstrap() {
    try {
      return await this.apiCall(`bootstrap`, "GET", null, false, true);
    } catch (error) {
      throw error;
    }
  }

  async getSystemDatas() {
    try {
      return await this.apiCall(`system-datas`, "GET", null, true, true);
    } catch (error) {
      throw error;
    }
  }
}
